import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges, ViewChild } from '@angular/core';import { LayoutService } from './service/app.layout.service';
import { StorageService } from '../_services/storage.service';
import { Router } from '@angular/router';
import { LocalStoreService } from '../LocalStoreService/local-store.service';

@Component({
    selector: 'app-profilemenu',
    templateUrl: './app.profilesidebar.component.html'
})
export class AppProfileSidebarComponent {

    nome: any = ''

    constructor(public layoutService: LayoutService,
        private router: Router, 
        private storageService: StorageService,
        private ls: LocalStoreService,
    ) {
        this.nome = this.ls.getItem('nome')
     }
    
    get visible(): boolean {
        return this.layoutService.state.profileSidebarVisible;
    }

    set visible(_val: boolean) {
        this.nome = this.ls.getItem('nome')
        this.layoutService.state.profileSidebarVisible = _val;
    }

    signOut(){
        this.layoutService.fecharProfileSidebar();
        this.storageService.logout();
        this.router.navigate(['/login']);
    }

    menuConfiguracao(){
        this.layoutService.fecharProfileSidebar();
        this.layoutService.showConfigSidebar();
    }

    configuracaoPerfil(){
        this.layoutService.fecharProfileSidebar();
        this.router.navigate(['/perfil']);
    }
    
}