import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, filter, take, switchMap } from 'rxjs/operators';
import { AuthService } from '../_services/auth.service';
import { LocalStoreService } from '../LocalStoreService/local-store.service';
import { Router } from '@angular/router';
import { StorageService } from '../_services/storage.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    private isRefreshing = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    

    constructor(
        private authService: AuthService, 
        private ls: LocalStoreService, 
        private router: Router,
        private storageService: StorageService
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError(error => {
                if (error instanceof HttpErrorResponse && error.status === 401) {
                    return this.handle401Error(request, next);
                } else if (error instanceof HttpErrorResponse && error.status === 403) {
                    return this.handle403Error(request, next)
                } else
                {
                    return throwError(error);
                }
            })
        );
    }

    private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
        if (!this.isRefreshing) {
            this.isRefreshing = true;
            this.refreshTokenSubject.next(null);

            return this.authService.refreshToken().pipe(
                switchMap((token: any) => {
                    this.isRefreshing = false;
                    this.ls.setItem('token', token.access_token);
                    this.ls.setItem('refresh_token', token.refresh_token);
                    this.refreshTokenSubject.next(token.access_token);
                    return next.handle(this.addTokenHeader(request, token.access_token));
                }),
                catchError((err) => {
                    this.isRefreshing = false;
                    this.authService.logout();
                    this.router.navigate(['/unauthorized']);
                    return throwError(err);
                })
            );
        } else {
            return this.refreshTokenSubject.pipe(
                filter(token => token != null),
                take(1),
                switchMap(token => next.handle(this.addTokenHeader(request, token)))
            );
        }
    }

    private handle403Error(request: HttpRequest<any>, next: HttpHandler) {
        this.storageService.logout();
        this.router.navigate(['/unauthorized']);
        return throwError(request);
    }

    private addTokenHeader(request: HttpRequest<any>, token: string) {
        return request.clone({
            setHeaders: {
                Authorization: `Bearer ${token}`
            }
        });
    }
}
