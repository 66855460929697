import { Component, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { LayoutService, AppConfig } from './layout/service/app.layout.service';
import { MenuService } from './layout/app.menu.service';
import { ActivatedRoute } from '@angular/router';
import { LocalStoreService } from './LocalStoreService/local-store.service';
import _ from "lodash";
import { fromEvent, interval } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Router } from '@angular/router';
import { StorageService } from './_services/storage.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
    login: any          = '';
    loading: boolean    = false;
    private TempoInatividade = 7200000;
    private reiniciarTempo: any;

    constructor(private primengConfig: PrimeNGConfig, private layoutService: LayoutService, private menuService: MenuService,
        private ls: LocalStoreService,
        private route: ActivatedRoute,
        private router: Router,
        private storageService: StorageService
    ) {
        fromEvent(document, 'mousemove').pipe(
            debounceTime(100)
          ).subscribe(() => {
            this.resetarTempo();
          });

        fromEvent(document, 'keypress')
        .pipe(debounceTime(1000))
        .subscribe(() => this.resetarTempo());

        this.inciarTempo();
     }

    ngOnInit(): void {
        //let preferencias = this.ls.getItem('preferencias') || {};
        let acoes = this.storageService.getUser() || {};
    
        const config: AppConfig = {
            ripple: true,                      //toggles ripple on and off
            inputStyle: 'filled',              //default style for input elements
            menuMode:acoes. preferencias.tipo_menu ?? 'slim-plus', //color scheme of the template, valid values are "light", "dim" and "dark"
            colorScheme: acoes.preferencias.tema ?? 'light',       //color scheme of the template, valid values are "light", "dim" and "dark"
            theme: acoes.preferencias.cor ?? 'blue',  //default component theme for PrimeNG
            menuTheme: acoes.preferencias.tema_menu ?? 'colorScheme',  //theme of the menu, valid values are "colorScheme", "primaryColor" and "transparent"
            scale: acoes.preferencias.escala ?? 12   //size of the body font size to scale the whole application
        };

        if(this.storageService.getOrigem() == "gap")
        {
            config.menuMode = "overlay";
        }

        this.layoutService.config.set(config);
    }

    private inciarTempo(): void {
       /*  this.reiniciarTempo = setTimeout(() => {
                this.storageService.logout();
                this.router.navigate(['/login']);
            }, 
            this.TempoInatividade
        ); */
    }
    
    private resetarTempo() {
        clearTimeout(this.reiniciarTempo);
        this.inciarTempo();
    }

}