import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { MenuService } from './app.menu.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStoreService } from '../LocalStoreService/local-store.service';
import _ from 'lodash';
import { LayoutService } from './service/app.layout.service';
import { StorageService } from '../_services/storage.service';

@Component({
  selector: 'app-menu',
  templateUrl: './app.menu.component.html',
})
export class AppMenuComponent implements OnInit {
  model: any = [];
  login: any = '';
  imagem: any = ''


  constructor(
    private menuService: MenuService,
    private ls: LocalStoreService,
    private route: ActivatedRoute,
    private layoutService: LayoutService,
    private router: Router,
    private storageService: StorageService
  ) {}

  veioGap: boolean = false;

  ngOnInit() {
    this.atualizarImagem();

    if(!this.storageService.isLoggedIn()) 
      {
        this.router.navigate(['/login']);
      }

      if (this.ls.getItem('gap')?.length > 0)
      {
          this.veioGap  = true;
      }
/* 
      this.model.push({
        label: 'INSS',
        icon: 'fa-brands fa-jenkins',
        items: [],
      });

      this.model.push({
        label: 'Financeiro',
        icon: 'fa-solid fa-wallet',
        items: [],
      });

      this.model.push({
        label: 'Suporte',
        icon: 'fa-duotone fa-solid fa-screwdriver-wrench',
        items: [],
      });

      this.model.push({
        label: 'Importação',
        icon: 'fa-solid fa-cloud-arrow-up',
        items: [],
      });
 
      this.model.push({
        label: 'FGTS',
        icon: 'fa-solid fa-brazilian-real-sign',
        items: [],
      });
      */

      this.menuService.getMenus().subscribe((menuItems: any) => {
        let total      = 0;
        let itens: any = []

        menuItems[0].children.forEach((item: any)=> {
          this.model.push({
            label: item.nome,
            icon: item.icon,
            items: [],
          });
        });
    
        let index = 0
        menuItems[0].children.forEach((item: any)=> {
          item.children.forEach((filho: any)=> {
            if (this.ls.findMenu(filho.rota))
            {
              let item = {
                label       : filho.nome,
                icon        : filho.icon,
                routerLink  : [filho.rota],
              }
              itens.push(item)
              this.model[index].items.push(item);
              total++;
            } 
          });
          index++
        });

        if (total <= 10)
        {
          this.model      = [];
          itens.forEach((item: any)=> { 
            this.model.push({
              label       : item.label,
              icon        : item.icon,
              routerLink  : item.routerLink,
            });
          })
        } else
        {
          this.model = this.model.filter((menu: any) => menu.items.length > 0);
        }
      });
     
      //INSS

     /*  if (this.ls.findMenu('consultainss')) {
        this.model[0].items.push({
          label: 'Consulta INSS',
          icon: 'pi pi-fw pi-home',
          routerLink: ['consultainss'],
        });
      }

      if (this.ls.findMenu('inssconfirmada')) {
        this.model[0].items.push({
          label: 'Digitacação INSS',
          icon: 'pi pi-verified',
          routerLink: ['inssconfirmada'],
        });
      }

      if (this.ls.findMenu('simulacaoinss')) {
        this.model[0].items.push({
          label: 'Tabelas',
          icon: 'pi pi-table',
          routerLink: ['simulacaoinss'],
        });
      }

      if (this.ls.findMenu('roteiro')) {
        this.model[0].items.push({
          label: 'Roteiros',
          icon: 'pi pi-th-large',
          routerLink: ['roteiro'],
        });
      }

      if (this.ls.findMenu('saldoextrato')) {
        this.model[0].items.push({
          label: 'Saldo Extrato',
          icon: 'pi pi-wallet',
          routerLink: ['saldoextrato'],
        });
      }

      //FINANCEIRO

      if (this.ls.findMenu('financeiraproposta')) {
        this.model[1].items.push({
          label: 'Financeira Proposta',
          icon: 'pi pi-briefcase',
          routerLink: ['financeiraproposta'],
        });
      }

      if (this.ls.findMenu('financeiraunidade')) {
        this.model[1].items.push({
          label: 'Financeira Unidade',
          icon: 'pi pi-building',
          routerLink: ['financeiraunidade'],
        });
      }

      if (this.ls.findMenu('contacorrente')) {
        this.model[1].items.push({
          label: 'Conta Corrente',
          icon: 'pi pi-wallet',
          routerLink: ['contacorrente'],
        });
      }

      if (this.ls.findMenu('unidadenegocios')) {
        this.model[1].items.push({
          label: 'Unidade de Negócios',
          icon: 'fa-solid fa-building-un',
          routerLink: ['unidadenegocios'],
        });
      }

      if (this.ls.findMenu('politicadepreco')) {
        this.model[1].items.push({
          label: 'Política de Preço',
          icon: 'pi pi-dollar',
          routerLink: ['politicadepreco'],
        });
      }

      if (this.ls.findMenu('promotora')) {
        this.model[1].items.push({
          label: 'Promotora',
          icon: 'pi pi-id-card',
          routerLink: ['promotora'],
        });
      }

      if (this.ls.findMenu('tabelacomissao')) {
        this.model[1].items.push({
          label: 'Tabelas de Comissão',
          icon: 'pi pi-money-bill',
          routerLink: ['tabelacomissao'],
        });
      }

      if (this.ls.findMenu('tabelacomercial')) {
        this.model[1].items.push({
          label: 'Tabela Comercial',
          icon: 'fa-thin fa-chart-bar',
          routerLink: ['tabelacomercial'],
        });
      }

      if (this.ls.findMenu('fechamento')) {
        this.model[1].items.push({
          label: 'Fechamento',
          icon: 'pi pi-check-circle',
          routerLink: ['fechamento'],
        });
      }

      if (this.ls.findMenu('historicofechamento')) {
        this.model[1].items.push({
          label: 'Historico de Fechamento',
          icon: 'fa-regular fa-timeline-arrow',
          routerLink: ['historicofechamento'],
        });
      }

      if (this.ls.findMenu('convenio')) {
        this.model[1].items.push({
          label: 'Convenio',
          icon: 'fa-light fa-sim-cards',
          routerLink: ['convenio'],
        });
      }

      if (this.ls.findMenu('formaContrato')) {
        this.model[1].items.push({
          label: 'Forma de Contrato',
          icon: 'fa-solid fa-file-contract',
          routerLink: ['formacontrato'],
        });
      }

      if (this.ls.findMenu('financeiraCia')) {
        this.model[1].items.push({
          label: 'Financeira Cia',
          icon: 'fa-solid fa-building-memo',
          routerLink: ['financeiracia'],
        });
      }

      if (this.ls.findMenu('spreads')) {
        this.model[1].items.push({
          label: 'spreads',
          icon: 'fa-solid fa-file-spreadsheet',
          routerLink: ['spread'],
        });
      }

      if (this.ls.findMenu('unidadenegociosnivel')) {
        this.model[1].items.push({
          label: 'Unidade Nível',
          icon: 'pi pi-sort-numeric-up',
          routerLink: ['unidadenivel'],
        });
      }
      if (this.ls.findMenu('esteira')) {
        this.model[1].items.push({
          label: 'Esteira',
          icon: 'pi pi-list',
          routerLink: ['esteira'],
        });
      }

      if (this.ls.findMenu('banco')) {
        this.model[1].items.push({
          label: 'Banco',
          icon: 'pi pi-credit-card',
          routerLink: ['banco'],
        });
      }
      if (this.ls.findMenu('permissao')) {
        this.model[1].items.push({
          label: 'Permissão',
          icon: 'pi pi-lock',
          routerLink: ['permissao'],
        });
      }

      if (this.ls.findMenu('conta')) {
        this.model[1].items.push({
          label: 'Conta',
          icon: 'pi pi-credit-card',
          routerLink: ['conta'],
        });
      }

      if (this.ls.findMenu('formapagamento')) {
        this.model[1].items.push({
          label: 'Forma de Pagamento',
          icon: 'fa-solid fa-money-check-dollar-pen',
          routerLink: ['formapagamento'],
        });
      }

      if (this.ls.findMenu('categoria')) {
        this.model[1].items.push({
          label: 'Categoria',
          icon: 'pi pi-clone',
          routerLink: ['categoria'],
        });
      }

      if (this.ls.findMenu('Saldo Unidade')) {
        this.model[1].items.push({
          label: 'Saldo Unidade',
          icon: 'fa-light fa-coins',
          routerLink: ['saldounidade'],
        });
      }

      //SUPORTE

      if (this.ls.findMenu('cofreSenha')) {
        this.model[2].items.push({
          label: 'Cofre de Senha',
          icon: 'pi pi-lock',
          routerLink: ['cofresenha'],
        });
      }

      if (this.ls.findMenu('statusproposta')) {
        this.model[2].items.push({
          label: 'Status Proposta',
          icon: 'pi pi-info',
          routerLink: ['statusproposta'],
        });
      }

      if (this.ls.findMenu('motivo')) {
        this.model[2].items.push({
          label: 'Motivo',
          icon: 'pi pi-sliders-h',
          routerLink: ['motivo'],
        });
      }

      if (this.ls.findMenu('tipodocumento')) {
        this.model[2].items.push({
          label: 'Tipo de Documento',
          icon: 'pi pi-id-card',
          routerLink: ['tipodocumento'],
        });
      }

      if (this.ls.findMenu('usuarios')) {
        this.model[2].items.push({
          label: 'Usuários ',
          icon: 'pi pi-users',
          routerLink: ['usuarios'],
        });
      }

      if (this.ls.findMenu('mensagem')) {
        this.model[2].items.push({
          label: 'Mensagem ',
          icon: 'pi pi-envelope',
          routerLink: ['mensagem'],
        });
      }

      if (this.ls.findMenu('menu')) {
        this.model[2].items.push({
          label: 'Menu ',
          icon: 'fa-solid fa-square-list',
          routerLink: ['menu'],
        });
      }
      
      //IMPORTACAO

      if (this.ls.findMenu('campo')) {
        this.model[3].items.push({
          label: 'Campo',
          icon: 'pi pi-tags',
          routerLink: ['campo'],
        });
      }

      if (this.ls.findMenu('layout')) {
        this.model[3].items.push({
          label: 'Layout',
          icon: 'pi pi-th-large',
          routerLink: ['layout'],
        });
      }

      if (this.ls.findMenu('statusapi')) {
        this.model[3].items.push({
          label: 'Status API',
          icon: 'pi pi-box',
          routerLink: ['statusapi'],
        });
      }

      if (this.ls.findMenu('comissaoapi')) {
        this.model[3].items.push({
          label: 'Tabela Comissão API',
          icon: 'pi pi-money-bill',
          routerLink: ['comissaoapi'],
        });
      }

      if (this.ls.findMenu('financeiraapi')) {
        this.model[3].items.push({
          label: 'Financeira API',
          icon: 'pi pi-book',
          routerLink: ['financeiraapi'],
        });
      }

      if (this.ls.findMenu('convenioapi')) {
        this.model[3].items.push({
          label: 'Convênio API ',
          icon: 'pi pi-tag',
          routerLink: ['convenioapi'],
        });
      }

      if (this.ls.findMenu('formacontratoapi')) {
        this.model[3].items.push({
          label: 'Forma de Contrato API',
          icon: 'pi pi-cloud-upload',
          routerLink: ['formacontratoapi'],
        });
      }

      if (this.ls.findMenu('finalidadeapi')) {
        this.model[3].items.push({
          label: 'Finalidade API',
          icon: 'pi pi-plus',
          routerLink: ['finalidadeapi'],
        });
      }

      if (this.ls.findMenu('usuarioapi')) {
        this.model[3].items.push({
          label: 'Usuário API',
          icon: 'pi pi-user',
          routerLink: ['usuarioapi'],
        });
      }

      if (this.ls.findMenu('bancoapi')) {
        this.model[3].items.push({
          label: 'Banco API',
          icon: 'pi pi-credit-card',
          routerLink: ['bancoapi'],
        });
      }

      if (this.ls.findMenu('motivoapi')) {
        this.model[3].items.push({
          label: 'Motivo API',
          icon: 'pi pi-pencil',
          routerLink: ['motivoapi'],
        });
      }

      if (this.ls.findMenu('importacao')) {
        this.model[3].items.push({
          label: 'Importação',
          icon: 'pi pi-cloud-upload',
          routerLink: ['importacao'],
        });
      }    

      //FGTS
      if (this.ls.findMenu('tabelasfgts')) {
        this.model[4].items.push({
          label: 'Tabelas Fgts',
          icon: 'pi pi-inbox',
          routerLink: ['tabelasfgts'],
        });
      }

      if (this.ls.findMenu('consultafgts')) {
        this.model[4].items.push({
          label: 'Consulta FGTS',
          icon: 'pi pi-calculator',
          routerLink: ['consultafgts'],
        });
      }

      if (this.ls.findMenu('esteirafgts')) {
        this.model[4].items.push({
          label: 'Esteira Consulta FGTS',
          icon: 'pi pi-bars',
          routerLink: ['esteirafgts'],
        });
      }

      if (this.ls.findMenu('situacao')) {
        this.model[4].items.push({
          label: 'Situacao ',
          icon: 'pi pi-slack',
          routerLink: ['situacao'],
        });
      }

      //ESTEIRA
      if (this.ls.findMenu('propostas')) {
        this.model[5].items.push({
          label: 'Propostas',
          icon: 'pi pi-tags',
          routerLink: ['propostas'],
        });
      } */
      
      setInterval(() => {
        this.atualizarImagem();
      }, 300000 );
  }

  atualizarImagem() {
    let foto = this.ls.getItem('foto');
    if (foto && foto?.length > 0) 
    {
      this.imagem = foto;
    } else
    {
      this.imagem = 'assets/demo/images/avatar/avatar_padrao.avif';
    }
  }

  onConfigButtonClick() {
    this.layoutService.showConfigSidebar();
  }

  onMenuButtonClick() {
    this.layoutService.onMenuToggle();
  }

  onProfileButtonClick() {
    this.layoutService.showProfileSidebar();
  }

  MobileView(): boolean {
    return window.innerWidth < 530;
  }

  menuMode(): boolean {
    let menu = this.layoutService.config().menuMode;
    return menu == 'horizontal';
}

  signOut(){
    this.layoutService.fecharProfileSidebar();
    this.storageService.logout();
    this.router.navigate(['/login']);
}
}
