import { Component, ElementRef, ViewChild } from '@angular/core';
import { LayoutService } from './service/app.layout.service';
import { StorageService } from '../_services/storage.service';
import { Router } from '@angular/router';
import { LocalStoreService } from '../LocalStoreService/local-store.service';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html'
})
export class AppTopbarComponent {

    imagem: any = ''

    @ViewChild('menubutton') menuButton!: ElementRef;

    constructor(public layoutService: LayoutService,
                        private storageService: StorageService,
                        private router: Router,
                        private ls: LocalStoreService,
    ) { 
        this.atualizarImagem();
        
    }
    ngOnInit() {
        setInterval(() => {
            this.atualizarImagem();
        }, 300000 );
      }
    
      atualizarImagem() {
        let foto = this.ls.getItem('foto');
        if (foto && foto?.length > 0) {
          this.imagem = foto;
        } else
        {
            this.imagem = 'assets/demo/images/avatar/avatar_padrao.avif';
        }
      }
    

    onMenuButtonClick() {
        this.layoutService.onMenuToggle();
    }

    onProfileButtonClick() {
        this.layoutService.showProfileSidebar();
    }
    onConfigButtonClick() {
        this.layoutService.showConfigSidebar();
    }
    
    MobileView(): boolean {
        return window.innerWidth <= 768; 
      }

      signOut(){
        this.layoutService.fecharProfileSidebar();
        this.storageService.logout();
        this.router.navigate(['/login']);
    }
}