import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalStoreService } from '../LocalStoreService/local-store.service';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {

  constructor(
    private ls: LocalStoreService,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
   const token = this.ls.getItem('token');
   //const token = 54545;
   //const token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiNjFlMjhiNjVhM2Q2YzhmZjJjMDIxMTM5MDI4MDRmNjYwZmExMGRlN2U3YzE4ODY4MDNmOTk1MTE5NjBiZTBhMGEzOWEyMjlhNTg1OTcxZWYiLCJpYXQiOjE3MjEzMzEzMDAuODc3NTU2LCJuYmYiOjE3MjEzMzEzMDAuODc3NTYsImV4cCI6MTc1Mjg2NzMwMC44NzIyOTMsInN1YiI6IjEiLCJzY29wZXMiOlsiY2FtcG8ubGlzdGFyIiwibGF5b3V0Lmxpc3RhciIsInN0YXR1c2FwaS5saXN0YXIiLCJjb21pc3Nhb2FwaS5saXN0YXIiLCJmaW5hbGlkYWRlYXBpLmxpc3RhciIsImZpbmFuY2VpcmFhcGkubGlzdGFyIiwidXN1YXJpb2FwaS5saXN0YXIiLCJpbXBvcnRhY2FvLmxpc3RhciIsImNhbXBvLmluc2VyaXIiLCJjYW1wby5hbHRlcmFyIiwiY2FtcG8uZXhjbHVpciIsImxheW91dC5pbnNlcmlyIiwibGF5b3V0LmFsdGVyYXIiLCJsYXlvdXQuZXhjbHVpciIsInN0YXR1c2FwaS5pbnNlcmlyIiwic3RhdHVzYXBpLmFsdGVyYXIiLCJzdGF0dXNhcGkuZXhjbHVpciIsImNvbWlzc2FvYXBpLmluc2VyaXIiLCJjb21pc3Nhb2FwaS5hbHRlcmFyIiwiY29taXNzYW9hcGkuZXhjbHVpciIsImZpbmFsaWRhZGVhcGkuaW5zZXJpciIsImZpbmFsaWRhZGVhcGkuYWx0ZXJhciIsImZpbmFsaWRhZGVhcGkuZXhjbHVpciIsImZpbmFuY2VpcmFhcGkuaW5zZXJpciIsImZpbmFuY2VpcmFhcGkuYWx0ZXJhciIsImZpbmFuY2VpcmFhcGkuZXhjbHVpciIsInVzdWFyaW9hcGkuaW5zZXJpciIsInVzdWFyaW9hcGkuYWx0ZXJhciIsInVzdWFyaW9hcGkuZXhjbHVpciIsImltcG9ydGFjYW8uaW5zZXJpciJdfQ.rLj8E3kmMgkT28x-NrFgfCkhVJifismIDd34loougX748blSvmFw5t29n4Gv2Q0uaqkE_9HCO1ddzwPj4EyFmY92RQsN3jj2NwY1Ov-rEyoiIwk4n_gPc5daRk2kEraf49KxO24BsTU3ixBCzk4pphV-Z-ftt2okMkiugPIAd1MrNiKp4SYQhiBwm2bSGV2hc255Jc_Ipi-F7J6E7s4SAyRKExPfTgVzTNZFc4ePDoEYe4WdvXxXCwJh8cWyJAAJIz3CT06LlkuLrkxPoHlAiV0N26-v7h7bGreVKZsLrKUytj6w57d8i6VI7yiqFodQ3SqeKxUvSQP13xrb24q53gw_ZdEOQ1zTf9VG_0IQBLpHWaGBeYPUaa3zfsR2InVlpUkh1ir6Muu7wvZFwwvk0i1cm5Sj6FumzV1PLza2Ea34w3YLoMiJxM4XrQwovRWMPwkY-FsSTdyMr7z3wg2KLqYE7pNBds_Ry2QBsr86TMIDP5sg5ofWCzWoszdo1a1wl9ilxioSmdGp3uCRSMvFiVLBXQP5tW3_7QLIl3MVjxYRDdB0bTeN4sIJqCwZqxkuccBlmgpW6uBRnvMHJLDf967DJYacTDiANtN2sjOo3slHadQATt_UpBm8Ii7ejj-c2qK6585_S1N68dD5wP4TIirGURfA1vmzpERKQVA5lWM"

    if (token) {
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
    }

    return next.handle(req);
  }
}