import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStoreService } from '../../LocalStoreService/local-store.service';

@Component({
    templateUrl: './dashboard.component.html'
})
export class DashboardComponent { 

    constructor(
        private router: Router, 
        private localStoreService: LocalStoreService
    ) {}

    home(){
        this.router.navigate(['/login']); 
    }

}