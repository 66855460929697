<div class="layout-sidebar" (mouseenter)="onMouseEnter()" (mouseleave)="onMouseLeave()">
      <div class="sidebar-header">
        <a [routerLink]="['/']" class="app-logo" style="margin-top: 5px;">
            <img src="assets/sys.png" alt="App Logo" class="app-logo-normal" style="width: 130px; height: 42px;" />
            <img src="assets/sys.png" alt="App Logo" class="app-logo-small"  style="width: 70px; height: 25px;" />
        </a>
        <!-- <button class="layout-sidebar-anchor p-link z-2 mb-2" type="button"></button> -->
    </div>  
 

    <div #menuContainer class="layout-menu-container">
        <app-menu></app-menu>
    </div>
</div>